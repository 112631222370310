<template>
  <div class="zt-component-main">
    <div class="component-label" style="display: inline-block;">
      <slot></slot>
      {{ widget.componentName }}：
    </div>
    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedRadio }}</div>
    <!--    <div style="display: inline-block; word-wrap: break-word;word-break: normal;">{{ computedWidgetCustomContent }}</div>-->

    <div flex-row-flex-start-flex-start class="tag-spacing">
      <span class="component-label">{{ combineName }}：</span>
      <div flex-row-flex-start-center>
        <template v-if="combineContent">
          <a :href="combineContent">{{ combineContent }}</a>
        </template>
      </div>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">

import ComponentMixin from './component-mixin'
import {isEmpty}      from 'lodash'

export default {
  extends   : ComponentMixin,
  mixins    : [],
  components: {},
  name      : 'ZtRadioCombineUrl',
  props     : {
    className: { type: String, default: '' },
    widget   : { type: Object, required: true },
  },
  data() {
    return {
      combineName   : '',
      combineContent: '',
    }
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    computedRadio() {
      let find = this.widget.optionList.find(item => {
        return item.optionValue == this.widget.answer
      })
      if (isEmpty(find)) {
        return ''
      }
      else {
        return find.optionName
      }
    },
  },
  methods : {
    //
  },
  created() {
    // document.documentElement.scrollTop = 0
  },
  destroyed() {},
  mounted() {
    if (isEmpty(this.widget)) {throw new Error('widget is empty')}
    if (Reflect.has(this.widget, 'answer') && this.widget) {
      this.combineName    = this.widget.combineComponent.combineName
      this.combineContent = this.widget.combineComponent.combineContent
    }
  },
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "component.scss";
</style>
